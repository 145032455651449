/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

/** this file is used by project to add new icons */
import dynamicHydrate from '@bluheadless/ui-logic/src/dynamic-hydration/dynamic-hydrate'
import EmptyIcon from '../icon/empty'

export * from './project-related-icons'
export const CounterIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/counter-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const CounterIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/counter-icon').then((mod) => mod.CounterIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const FlightIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/flight-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const FlightIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/flight-icon').then((mod) => mod.FlightIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const PersonIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/person-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const PersonIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/person-icon').then((mod) => mod.PersonIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const SuitcaseIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/suitcase-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const SuitcaseIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/suitcase-icon').then((mod) => mod.SuitcaseIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
